$(
	function() {
		if (!app._VERSION) {
            app._VERSION = 1;
		}
		if (app._VERSION === 1 || app._VERSION_TRANS_NOT_COMPLETED) {
            app.correctProductNameHeight();
		}
		app.initializeScrollProdName();
	}
);

/**
 * Initializes mouse over for Scrolling Product Name
 *
 * @returns {void}
 */
app.initializeScrollProdName = function() {
	if (this.productNameViewType === 'multiLine') {
		return;
	}
	$('.scrollable .product-list2 .item, .scrollable .product-list2 .item *')
		.mouseenter (
			function() {
				if (!$(this).hasClass('item')) {
					var $item = $(this).closest('.item');
                    $item.addClass('__scroll-init');
					$item.trigger('mouseenter');
					return ;
				}
				var that = this;
				clearTimeout($(this).data('scroll-timer-id'));
				var timerId = setTimeout(
					function() {
						app.scrollProductName(that, 1);
					},
					400
				);
				$(this).data('scroll-timer-id', timerId);
			}
		)
		.mouseleave(
			function() {
				clearTimeout($(this).data('scroll-timer-id'));
				var timerId = app.scrollProductName(this, 2);
				$(this).data('scroll-timer-id', timerId);
			}
		)
};

/**
 * Makes scrolling process for Labels
 *
 * @param {Object} item
 * @param {number} step
 * @returns {void}
 */
app.scrollProductName = function(item, step) {
	var $item = $(item);
	var $link = $item.find('.name a');
	var $divName = $item.find('div.name')
		.css('overflow', 'hidden');
	var itemWd = $divName.width();

	if ($link.css('overflow') === 'hidden' || $link.data('duration') !== '') {
		if (step === 1) {
			$link.css({
				display: 'inline-block',
				overflow: 'visible',
				transition: 'margin 0s',
				width: 'auto'
			});
			var wd = $link.outerWidth();
			if (wd > itemWd) {
				var delta = wd - itemWd;
				var k = wd / itemWd;
				var itemDuration = 600 +  2200 * k;

                $link.data('prod-scroll-name-delta', delta);
				$link.data('prod-scroll-name-duration', itemDuration);

				$link.stop()
					.animate(
					{
						marginLeft: -delta
					},
					{
						duration: itemDuration,
						queue: false,
						complete : function() {
							clearTimeout($(item).data('scroll-timer-id'));
							var timerId = setTimeout(
								function() {
									app.scrollProductName(item, 3);
								},
								400
							);
							$(item).data('scroll-timer-id', timerId);
						}
					}
				);
			} else {
				$link.data('scrollable', 'N');
				$link.css({
					display: 'block',
					overflow: 'hidden'
				});
			}
		} else {
			clearTimeout($(item).data('scroll-timer-id'));
			var backDuration = $link.data('prod-scroll-name-duration');
			if (!Number (backDuration)) {
				return ;
			}
			if (step === 2) {
				var curMrg = Math.abs(parseInt($link.css('margin-left')));
				backDuration = backDuration * curMrg / $link.data('prod-scroll-name-delta');
			}
			$link
				.stop()
				.animate(
				{
					marginLeft: 0
				},
				{
					duration: backDuration,
					queue: false,
					complete : function() {
						if (step === 3) {
							clearTimeout($(item).data('scroll-timer-id'));
							var timerId = setTimeout(
								function() {
									app.scrollProductName(item, 1);
								},
								400
							);
							$(item).data('scroll-timer-id', timerId);
							return ;
						}

						$link.css({
							overflow: 'hidden',
							display: 'block'
						});
					}
				}
			)
		}
	}
};

/**
 * Corrects Name height in product list - to be the same for all products, without overriding
 *
 * @param {boolean} fadeInList
 * @returns {boolean}
 */
app.correctProductNameHeight = function(fadeInList) {
	/*we work only for VERSION 1*/
	if (app._VERSION > 1 && !app._VERSION_TRANS_NOT_COMPLETED) {
		return false;
	}
	var sumHeight = 0;
	var $prodList = $('.product-list');
	if ($prodList.find('.product-list2.line').length > 0) {
		return false;
	}
	if (this.productNameViewType === 'multiLine') {
		$prodList.addClass('multiLine');
	} else if ($prodList.hasClass('overflow')) {
		return false;
	}
	if (!app.prodNameCorrection) {
		app.prodNameCorrection = 0;
	}
	if (!app.prodItemCorrection) {
		app.prodItemCorrection = 0;
	}
	var rowPos = -1;
	var topPos = -1;
	var $products = $('.product-list2 .item');
	if (!app.iniProdItemHeight) {
		app.iniProdItemHeight = $products.height();
	}
	$products.each(
		function () {
			var pos = $(this).position();
			if (pos.top > topPos + $(this).height() * .7 || topPos < 0) {
				topPos = pos.top;
				rowPos++;
			}
			$(this)[0].className = $(this)[0].className.replace(/row-\d+/g, '');
			$(this).addClass('row-' + rowPos);
		}
	);

	for (var i = 0; i < 500; i++) {
		var $items = $('.grid .row-' + i + ' .name');
		if ($items.length === 0) {
			break;
		}
		var tmp = $items.find('a').map(
			function() {
				return $(this).height();
			}
		);
		var max = 40;
		for (var a = 0; a < tmp.length; a++) {
			if (tmp[a] > max) {
				max = tmp[a];
			}
		}
		sumHeight += max;
		$items.height(max + app.prodNameCorrection);
		$(".row-" + i).height(app.iniProdItemHeight + max + app.prodItemCorrection);
		$(".grid .row-" + i + " .name a").each(
			function() {
				$(this).css({
					'padding-top': Math.ceil((max - $(this).height()) / 2),
					'text-align': 'center'
				})
			}
		);
		app.gridLineCorrection = true;
	}
	var $listHidden = $('.list-hidden');
	if (!fadeInList && $listHidden.length > 0) {
		var $panel = $('.panel-1').hide();
		$listHidden.removeClass('list-hidden');
		$panel.fadeToggle(100);
	}
	if (app._VERSION === 1 || app._VERSION_TRANS_NOT_COMPLETED) {
        setTimeout(
            function() {
                $('.grid .name').animate(
                    {
                        opacity: 1
                    }
                );
            },
            500
        );
        if (sumHeight !== app._fullNameSumHeight) {
            app._fullNameSumHeight = sumHeight;
            setTimeout(
                app.correctProductNameHeight,
                400
            );
        }
	}
	return true;
};